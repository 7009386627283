import request from '@/utils/request';
const url = "https://huanxinxiang.ai-me.cn";
import { setAppName } from "@/utils/common";
const appName = setAppName();

// 支付宝支付-需要短信验证码
export const codeDlipayPay = (ip, data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  ipBaseURL: ip,
  headers: {
    "App-Name": appName,
  },
  data,
});

// 获取短信
export const getCode = (ip, data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  ipBaseURL: ip,
  data,
});

// 支付宝签约刷单-无需Token
export const alipaySinglePayAPI = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-single-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-zhengxing',
  },
});

// 支付宝签约刷单-无需Token
// export const alipaySinglePay = (data) => request({
//   url: '/huanxinxiang-service/v1/alipay-single-pay/',
//   method: 'post',
//   data,
//   ipBaseURL: url,
//   headers: {
//     "App-Name": appName,
//   },
// });

// 支付宝单笔支付刷单-无需验证码-无需Token
export const alipayNoSignPayAPI = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-zhengxing',
  },
});

// hm
export const alipayNoSignPayHMAPI = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-huimo',
  },
});

// hy
export const alipayNoSignPayHYAPI = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-heyou',
  },
});

// 秋末1
export const alipayNoSignPayQiuMo1 = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-qiumo1',
  },
});
// 莱可优1
export const alipayNoSignPayLikeU1 = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-laikeyou1',
  },
});
// 哒丝坦
export const alipayNoSignPayDasitan = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-dasitan',
  },
});

// 帖芯
export const alipayNoSignPayTiexin = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-tiexin',
  },
});
// 卉墨
export const alipayNoSignPayHuimo = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-huimo',
  },
});

// 鹤游
export const alipayNoSignPayHeyou = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  params,
  data,
  headers: {
    "App-Name": 'huanxinxiang-heyou',
  },
});

// // 支付宝签约刷单-无需Token 根据域名设置主体
export const alipaySinglePayAppName = (params, data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-single-pay/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    params,
    data,
  });
